.family__calendar {
    background: #fff;
    width: 310px;
    padding: 6px;

    .calendar__head {
        display: grid;
        grid-template-columns: 30px auto 30px;
        justify-content: space-between;
        text-transform: capitalize;

        button {
            padding: 0px;
            margin: 0px;
            background: transparent;
            color: #333;

            &:hover {
                color: #a98c93;
            }
        }

        span {
            font-size: 1.4rem;
        }
    }

    .calendar__body {
        display: grid;
        grid-template-columns: repeat(7, 40px);
        grid-template-rows: repeat(7, 40px);
        padding: 12px;

        .calendar__day {
            border: 1px solid transparent;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            padding: 3px;
            place-content: center;
            align-items: center;
            transition: all 0.2s ease-in-out;

            &:not(.disabled) {
                cursor: pointer;

                &:hover {
                    background: #2985e77e;
                    color: #fff;
                }
            }

            &.today {
                background: #a98c9334;
                border: 1px solid #a98c93;
            }
        }
    }
}