@font-face {
  font-family: 'Quentin';
  src: local('Quentin'), url('./fonts/quentin.otf') format('opentype');
}

:root {
  --color-theme: #46476E;
  --button-background: #46476E;
  --button-color: #fff;
  --button-hover-background: #fff;
  --button-hover-color: #46476E;
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
  background: #F0EDEA;
  color: #333;
  min-height: 100vh;
}

input, textarea, select {
    font-size: 16px; /* Minimum font size to prevent zooming on iPhone */
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s, box-shadow 0.3s;
  }
  textarea {
    resize: none;
    min-height: 120px;
  }
  
  input:focus, textarea:focus {
    border-color: #C1D2D8;
    box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
    outline: none;
  }

  button {
    padding: 8px 20px;
    border: none;
    outline: none;
    background: var(--button-background);
    color: var(--button-color);
    font-size: 1rem;
    transition: .3s ease-in-out;
    cursor: pointer;
    border: 2px solid transparent;
    text-decoration: none;
  }

  button:has(i) {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 0.5rem;
  }

  button.cta{
    background-color: var(--color-theme);
    color: #fff;
  }

  button:hover {
    background: var(--button-hover-background);
    color: var(--button-hover-color);
    border: 2px solid transparent;
  }

  .styled__heading {
    font-family: 'Quentin', sans-serif;
    text-align: center;
    color: var(--color-theme);
    font-size: 4rem;
    font-weight: 200;
  }

  @media screen and (max-width: 768px) {
    .styled__heading {
      font-size: 2.5rem;
    }
  }

  .component__loader{
    display: grid;
    height: 100vh;
    place-items: center;
  }

  a {
    text-decoration: none;
  }