.signup__main {
    display: grid;

    @media screen and (min-width: 800px) {
        grid-template-columns: 1fr 1fr;
        padding: 20px;
        max-width: 1000px;
        margin: 0px auto;
    }

    .signup__form__header {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 20px;
        border: 1px solid #222;
        background: #46476E;
        color: #fff;
        text-align: center;

        h1 {
            font-size: 3rem;
            color: #fff;
        }

        img {
            width: 200px;
            object-fit: contain;
            margin: 20px auto;
        }
    }

    .signup__form__container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 20px;
        border: 1px solid #ccc;
        background: #f3f3f3;

        h1 {
            text-align: center;
            margin-bottom: 20px;
        }

        label {
            display: grid;

            span {
                margin: 3px;
            }
        }

        .phone__container {
            display: grid;
            grid-template-columns: 6rem auto;
            gap: 4px;

            input {
                width: 100%;
            }
        }

        .form__toolbar {
            display: flex;
            flex-direction: row;
            gap: 8px;

            button {
                margin-left: auto;
            }
        }

        a,
        a:visited,
        a:hover {
            color: #333;
            text-align: right;
        }
    }
}