#loading__background{
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    background: #8d8d8d7a;
    backdrop-filter: blur(2px);
}
#loading__container{
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    display: grid;
    place-content: center;
    .loading__title{
        font-size: 3rem;
        color: #fff;
    }
}