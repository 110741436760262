div#family__landing__main,
div.family__public__main {
    background: rgb(255, 248, 240);
    background: linear-gradient(90deg, #c1d2d8 0%, #DCC6ae 100%);
    color: #333;
    padding: 8px;
    min-height: 100vh;

    @media only screen and (min-width: 992px) {
        padding: 50px;
    }

    div.padded__container {
        background: #f2f0ee;
        padding: 20px;
        max-width: 900px;
        margin: 0px auto;
    }

    .container__image {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 500px;
        max-width: 90%;
        margin-top: 20px;
        margin-bottom: 30px;
        border: 1px solid #ccc;
        box-shadow: 0 4px 6px #b19d9988;
    }

    div.hiro__container {
        height: 400px;
        border: 1px solid rgb(42, 42, 42);
        display: flex;
        flex-direction: column;
        place-content: center;
        justify-self: start;
        align-items: center;
        margin-bottom: 20px;
        text-align: center;

        h1.header {
            font-size: 3.1rem;
            text-transform: capitalize;

            @media only screen and (min-width: 992px) {
                font-size: 4.2rem;
            }
        }
    }

    div.content {
        padding: 20px;

        h2 {
            font-size: 2rem;
            margin-bottom: 1rem;
            letter-spacing: 0.8px;
            text-align: center;
        }

        p {
            font-size: 1.1rem;
            margin-bottom: 1rem;
            line-height: 1.8;
            letter-spacing: 0.5px;
        }

        margin-bottom: 40px;
    }

    .feature__container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .feature {
        background: #fff;
        padding: 1rem;
        margin: 1rem;
        width: 300px;
        text-align: center;
        box-shadow: 0 4px 6px #b19d9988;

        .feature-title {
            font-size: 1.3rem;
            margin-bottom: 0.5rem;
            letter-spacing: 0.8px;
        }
    }

    form {
        display: grid;
        gap: 12px;
        max-width: 500px;
        margin: 0px auto;

        label {
            display: grid;
        }

        button {
            padding: 8px;
            outline: none;
            border: 2px solid #f44336;
            background: #f44336;
            color: rgb(255, 255, 255);
        }

        .application__information {
            font-size: 0.9rem;
            letter-spacing: normal;
            line-height: normal;
            color: #666666;
        }
    }

    .timeline__feature {
        background: #fff;
        padding: 1rem;
        margin: 1rem;
        width: 400px;
        text-align: center;
        box-shadow: 0 4px 6px #b19d9988;

        .feature-title {
            font-size: 1.3rem;
            margin-bottom: 0.5rem;
            letter-spacing: 0.8px;
        }
    }

    .faq__feature {
        background: #fff;
        padding: 1rem;
        margin: 1rem;
        text-align: center;
        box-shadow: 0 4px 6px #b19d9988;

        .feature-title {
            font-size: 1.3rem;
            margin-bottom: 0.5rem;
            letter-spacing: 0.8px;
        }
    }

    footer {
        margin-top: 10vh;
        background: #333;
        color: #fff;
        padding: 20px;
        display: grid;
        grid-template-columns: 1fr;

        @media only screen and (min-width: 992px) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        gap: 20px;

        div {
            padding: 20px;

            form {
                margin: 12px;
            }
        }

        .link__container {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }

        a,
        a:visited,
        a:hover {
            color: #e0e0e0;
        }
    }
}

.message__accepted {
    background: rgba(67, 118, 71, 0.431);
    padding: 40px;

    h2 {
        text-align: center;
    }
}

div.family__public__main {
    display: flex;
    flex-direction: column;
    gap: 12px;

    h1,
    h2 {
        letter-spacing: 3px;
        margin-bottom: 10px;
    }

    p,
    ul {
        font-size: 1.1rem;
        margin-bottom: 1rem;
        line-height: 1.8;
        letter-spacing: 0.5px;

        li {
            margin-left: 12px;
        }

        max-width: 600px;
    }
}