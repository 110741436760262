#hub__chat {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 80px auto 80px;
    height: 100svh;

    .hub__chat__messages {
        display: flex;
        flex-direction: column-reverse;
        background: #f4f4f4;
        background: linear-gradient(180deg, #9a9a9a1b 0%, #fff 70%);
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
        padding: 0.5rem;
        border-top: 1px solid #eee;
        color: #333;

        .chat__message {
            padding: 6px;
            margin: 6px;
            border: 1px solid #ccc;
            border-radius: 4px;

            &.own__message {
                background-color: #c6ddf6;
                text-align: right;
            }
        }
    }

    form {
        grid-row: 3; // Form at the bottom
        display: flex;
        padding: 0.5rem;
        background: #fff; // Optional: background for form area
        border-top: 1px solid #ddd; // Optional: border to separate form visually

        input {
            flex: 1;
            padding: 0.5rem;
            border: 1px solid #ccc;
            border-radius: 4px;
            margin-right: 0.5rem;
        }

        button {
            padding: 0.5rem 1rem;
            border: none;
            background-color: #007bff;
            color: #fff;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
                background-color: #0056b3;
            }
        }
    }
}